
export function getDomainUrl() {
  return new URL(window.location.href).origin
}

export function isClapswap() {
  return new URL(window.location.href).origin.includes('clapswap')
}

export function isKodo() {
  return new URL(window.location.href).origin.includes('kodo')
}

export function getRedirectUrl() {
  return getDomainUrl() + '/redirect'
}

export function getTitle() {
  if (isClapswap()) {
    return 'Clapswap'
  } else {
    return 'KODO'
  }
}
