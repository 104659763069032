import React from 'react'
import { useRoutes } from "react-router-dom"
import {
  BarChartOutlined,
  BookOutlined,
  DesktopOutlined,
  ProfileOutlined,
  ReadOutlined,
  UserOutlined,
} from "@ant-design/icons"

import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage'
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage'
import RedirectPage from '../Pages/RedirectPage'
import Codes from '../Pages/Codes'
import Config from '../Pages/Config'
import Users from "../Pages/Users";
import Transactions from "../Pages/Transactions";
import Payouts from "../Pages/Payouts";
import Cards from "../Pages/Cards";


function Routes() {
  const element = useRoutes([{
    path: '/sign-in',
    exact: true,
    element: <RedirectUserToMainPage />,
  }, {
    path: '/redirect',
    exact: true,
    element: <RedirectPage />,
  },
  {
    path: '/',
    element: <RedirectGuestToSignInPage />,
    children: [{
      path: '/',
      exact: true,
      element: <Codes />,
    }, {
      path: '/config',
      exact: true,
      element: <Config />,
    }, {
      path: '/users',
      exact: true,
      element: <Users />,
    }, {
      path: '/transactions',
      exact: true,
      element: <Transactions />,
    }, {
      path: '/payouts',
      exact: true,
      element: <Payouts />,
    }, {
      path: '/cards',
      exact: true,
      element: <Cards />,
    }
    ]
  }
  ]);
  return element;
}

export const links = [{
  href: '/',
  icon: <UserOutlined />,
  title: 'Codes',
}, {
  href: '/users',
  icon: <ReadOutlined />,
  title: 'Users',
}, {
  href: '/payouts',
  icon: <BookOutlined />,
  title: 'Payouts',
}, {
  href: '/cards',
  icon: <DesktopOutlined />,
  title: 'Cards',
}, {
  href: 'https://metabase.quantummosaic.net/question/71-gift-cards-monthly-stat',
  icon: <BarChartOutlined />,
  title: 'Monthly Stat',
  external: true
}, {
  href: 'https://metabase.quantummosaic.net/question/72-gift-cards-daily-stat',
  icon: <BarChartOutlined />,
  title: 'Daily Stat',
  external: true
},
// {
//   href: '/proxies',
//   icon: <GlobalOutlined/>,
//   title: 'Proxies',
// },
// {
//   href: '/redeem-order',
//   icon: <FormOutlined/>,
//   title: 'Redeem order',
// },
// {
//   href: '/algo-statistics',
//   icon: <BarChartOutlined/>,
//   title: 'Algo statistics',
// },
// {
//   href: '/lost-and-found',
//   icon: <CompassOutlined/>,
//   title: 'Lost and found',
// },
// {
//   href: '/accounts_to_create',
//   icon: <UserAddOutlined/>,
//   title: 'Accounts to create',
// },
{
  href: '/config',
  icon: <ProfileOutlined />,
  title: 'Config',
},
];

export default Routes;
