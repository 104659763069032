import React, {useCallback, useEffect, useState} from 'react'
import {Table} from 'antd'
import b_ from 'b_'
import TableSettings from "../../Components/TableSettings"
import ActionButtons from './ActionButtons'
import useStorageState from '../../Utils/useStorageState'
import PrintDate from "../../Components/Prints/PrintDate";
import CodeFilters from "./CodeFilters";
import {Link} from "react-router-dom";
import PrintCodeStatus from "../../Components/Prints/CodeStatus";
import Expandable from "./Expandable";

const b = b_.lock('CodesTable')


const platformsToLink = {
  'xbox': 'redeem-jobs',
  'blizzard': 'redeem-job',
  'steam': 'redeem-job',
  'amazon': 'redeem-job',
  'resell': 'check-codes-jobs'
}

const amountRegEx = /:\d+$/gm

function CodesTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                      platforms,
                      statuses
                    }) {
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'Pack',
        dataIndex: 'packId',
        key: 'packId',
        width: 80,
        fixed: 'left',
        render: (id, {packId}) => <Link to={`/?limit=50&offset=0&filters[packId][]=${packId}`}>{packId}</Link>
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        fixed: 'left',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: 300,
        fixed: 'left',
      },
      {
        title: 'Code jobs',
        dataIndex: 'code',
        key: 'code',
        width: 100,
        render: (code, {platform}) => {
          let codeToformat
        if (amountRegEx.test(code)) {
          codeToformat = code.split(":")[0] 
        } else {
          codeToformat = code
        }
        return (    
          <>
        <a target="_blank" rel="noreferrer" href={platform.startsWith("R-") ? `https://resell.quantummosaic.net/check-codes-jobs?limit=50&offset=0&filters[code][]=${codeToformat}` : `https://${platform}.quantummosaic.net/${platformsToLink[platform.toLowerCase()]}?limit=50&offset=0&filters[code][]=${codeToformat}`}>
          Jobs
        </a>
        {platform.startsWith("R-") && <><span> | </span><a target="_blank" rel="noreferrer" href={`https://resell.quantummosaic.net/code-stock?limit=50&offset=0&filters[code][]=${codeToformat}`}>Stock</a></>}
        </>
        )
        }
      },
      {
        title: 'User',
        dataIndex: 'userEmail',
        key: 'userEmail',
        width: 300,
        render: (id, {userEmail}) => <Link to={`users?limit=50&offset=0&filters[email][]=${userEmail}`}>{userEmail}</Link>
      },
      {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
        width: 100,
        filters: platforms,
        filteredValue: filters['platform'] || null,
        filterMultiple: false
      },
      {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        width: 60,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: statuses,
        filteredValue: filters['status'] || null,
        filterMultiple: false,
        width: 100,
        render: status => status && <PrintCodeStatus codeStatus={status} />
      },
      {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        width: 150
      },
      {
        title: "Status changed at",
        dataIndex: "statusTs",
        key: 'statusTs',
        width: 250,
        render: (create) => <PrintDate withTime date={create}/>,
      },
      {
        title: 'Reward',
        dataIndex: 'reward',
        key: 'reward',
        width: 100,
        align: 'right'
      },
      {
        title: 'Reward USD',
        dataIndex: 'rewardUsd',
        key: 'rewardUsd',
        width: 150,
        align: 'right'
      },
      {
        title: 'Reward %',
        dataIndex: 'rewardPercent',
        key: 'rewardPercent',
        width: 100,
        align: 'right'
      },
      {
        title: 'Code Value',
        dataIndex: 'codeValue',
        key: 'codeValue',
        width: 100,
        align: 'right'
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        width: 150,
        render: (id, {notes}) => <Link to={`/?limit=50&offset=0&filters[notes][]=${notes}`}>{notes}</Link>
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        render: (_, record) => record.id && <ActionButtons record={record} afterUpdate={afterUpdate}/>
      },
    ];
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('codes_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < columns.length,
      checkAll: checkedList.length === columns.length,
    });
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
        allCheckList.push(e[Object.keys(e)[1]])
        setCheckbox(allCheckList)
      })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
    if (AllCheckbox.checkAll) {
      setAllCheckbox({
        checkedList: GetColumns().map(e => e.title),
        indeterminate: false,
        checkAll: true,
      })
    }
  }, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
      checkedList: (e.target.checked && columns.map(e => e.title)) || [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }, [Checkboxs]);

  useEffect(() => {
    setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  // const loader = Object.keys(loadings).some(e => !!loadings[e]) || loading
  return <div className={b()}>
    <CodeFilters filters={filters} onFilter={onFilter} className="mb"/>
    <Table
      rowKey={(record) => `${record.id}_${record.balance_eur}`}
      className={b()}
      bordered
      dataSource={list}
      scroll={{x: "max-content"}}
      size="middle"
      onChange={(a, b, c) => tableOnChange(a, b, c)}
      onFilter={onFilter}
      pagination={pagination}
      loading={loading}
      columns={columnsFilter}
      rowClassName={() => b('lock')}
      title={() => <TableSettings
        onChangeCheckAll={onChangeCheckAll}
        allChecks={AllCheckbox}
        Checkboxs={AllCheckbox.checkedList}
        TableColumn={() => GetColumns()}
        setCheckbox={onChangeCheckList}
      />}
      expandable={{
        expandedRowRender: record => <Expandable record={record} />,
      }}
    />
  </div>
}

export default CodesTable;
